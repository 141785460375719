<template>
  <v-btn
    class="my-1 py-5 rounded-pill h-auto w-100 text-capitalize"
    :class="selected ? 'selected-class' : null"
    min-width="auto"
    :color="selected ? 'secondary10' : 'neutral10'"
    elevation="0"
    variant="outlined"
    :style="{ letterSpacing: 'inherit' }"
    :ripple="false"
    :loading="loading"
    @click="handleClick"
  >
    <slot name="default" />
  </v-btn>
</template>

<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>

<style lang="scss" scoped>
.v-btn--variant-outlined {
  border: thin solid rgba(var(--v-theme-neutralVariant50));

  &.selected-class {
    background: rgba(var(--v-theme-secondary90), 1);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    transition: background .1s ease-in-out;
  }

  &:hover:before {
    background: rgba(var(--v-theme-secondary10), 0.08);
  }
}
</style>
